export const BotSkeleton = () => (
  <div className="rounded-xl border-2 border-primary animate-pulse flex flex-col justify-end gap-2 overflow-hidden">
    <div className="h-[250px] lg:h-[300px] border-b border-primary bg-secondary" />
    <div className="flex flex-col gap-4 p-3">
      <div className="flex items-center justify-between">
        <div className="bg-secondary w-1/2 h-5 rounded-full" />
        <div className="bg-secondary w-14 h-4 rounded-full" />
      </div>
      <div className="bg-secondary w-full h-8 rounded-full" />
    </div>
  </div>
);
